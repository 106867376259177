<template>
  <v-virtual-scroll
      :items="items"
      :height="height"
      item-height="64"
      v-if="items.length > 0"
  >
    <template v-slot:default="{ item }">
      <v-list-item :key="item.id" @click="openTask(item.id)">
        <v-list-item-action>
          <v-btn
              fab
              small
              depressed
              color="primary"
              v-if="item.status !== 'Erledigt'"
              @click="openTask(item.id)"
          >
            <v-icon>mdi-format-list-checks</v-icon>
          </v-btn>
          <v-btn
              fab
              small
              depressed
              color=""
              v-else
              @click="openTask(item.id)"
          >
            <v-icon v-if="item.status === 'Erledigt'">mdi-check</v-icon>

          </v-btn>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>
            <v-icon v-if="item.prio === 'Hoch'">mdi-chevron-double-up</v-icon>
            <v-icon v-if="item.prio === 'Mittel' ">mdi-equal</v-icon>
            <v-icon v-if="item.prio === 'Niedrig'">mdi-chevron-double-down</v-icon>
            <v-icon v-if="item.prio === null">mdi-circle-small</v-icon>

            <div class="d-inline ps-2">
              <s v-if="item.status === 'Erledigt'">
                <strong class="grey--text">{{ item.subject }}</strong>
              </s>
              <span v-else>
                <strong>{{ item.subject }}</strong>
              </span>
            </div>
            <span v-if="item.date" class="ps-2">
              <v-icon>mdi-circle-small</v-icon>
              <v-icon>mdi-calendar</v-icon>
              {{ getStringDate(item.date) }}
            </span>
            <span v-if="item.description">
              <v-icon>mdi-circle-small</v-icon>
              {{ item.description }}
            </span>

            <!--
            <span v-if="item.weddingId" class="ps-2">
                 <v-icon>mdi-ring</v-icon>
              {{ item.weddingId }}
            </span>
            -->
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action @click="openTask(item.id)">
          <v-icon small>
            mdi-arrow-right
          </v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
    </template>
  </v-virtual-scroll>
  <NoResultSnippet
      v-else
      :text="noResultsText">
  </NoResultSnippet>
</template>

<script>
import {date2String} from "@/helper/helper";

const NoResultSnippet = () => import("@/components/generalUI/NoResultSnippet");

export default {
  name: "Message",
  components: {
    NoResultSnippet
  },
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    items: {
      type: Array,
      default() {
        return []
      }
    },
    height: {
      type: Number,
      default() {
        return 320
      }
    },
    noResultsText: String
  },
  methods: {
    openTask(id) {
      this.$router.push('/planner/tasks/task/' + id)
    },
    getStringDate(date) {
      return date2String(date)
    }
  }
}
</script>
